import { useStaticQuery, graphql } from "gatsby";

// http://localhost:8000/___graphq for GraphiQL

export const useAirtableData = (count = 10) => {
  const newsDataRaw = useStaticQuery(graphql`
    query NewsQuery {
      allAirtable(
        limit: 20, 
        sort: {order: DESC, fields: data___Created}
        filter: {data: {Show: {eq: true}}, table: {eq: "News"}}
      ) {
        nodes {
          data {
            Content
            Created
            Image {
              thumbnails {
                large {
                  url
                }
              }
            }
            Title
          }
          id
        }
        totalCount
      }
    }
  `)
  // console.log(newsData.allAirtable);

  const newsData = newsDataRaw.allAirtable.nodes
    .filter((node, index) => index < count && node.data.Title && node.data.Content)
    .map(node => {
      const { Title: title, Content: content, Image:image } = node.data;

      return {
        id: node.id,
        title,
        content,
        imageSrc: Array.isArray(image) && image[0] && image[0].thumbnails && image[0].thumbnails.large ? image[0].thumbnails.large.url : ""
      }
    })

  return {
    totalCount: newsDataRaw.allAirtable.totalCount,
    newsData
  }
};