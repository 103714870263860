import React, { useState, useMemo } from "react"
import { Layout, Heading, Seo } from "../components";
import { useAirtableData } from "../dynamicData";

const metaData = {
  title: "最新消息-用最甜的價格，玩最棒的密室！徵的就是最有趣的你！",
  description: "平日優惠、學生優惠、連刷優惠、慶生優惠，精挑細選的優惠內容，等你來使用！還有不定時掉落的店內小活動，快來挑戰！本店遺失最會講故事、最愛與人互動、最喜歡操作機關的現場執行們，你們快回來啊！我們最親愛的帶場人員！",
  image: null
}

// Load 10 initially, load more 10 when load more

const News = ({ title, content, imageSrc, isLast }) => {
  return (
    <div className={`flex flex-col md:flex-row gap-12 md:gap-8 lg:gap-24 py-12 md:py-20 ${isLast ? "" : "border-b border-primary-heavy"}`}>
      <div className="relative w-[310px] h-[310px] md:w-[376px] md:h-[376px]">
        <img src={imageSrc} alt={title} className="relative w-full h-full rounded z-10" />
        <div className="absolute top-2.5 left-2.5 w-full h-full border border-grey rounded"/>
      </div>
      <div className="grow md:max-w-[50%]">
        <Heading variant="h3">{title}</Heading>
        <p className="py-4 md:text-xl text-grey whitespace-pre-line leading-normal">
          {content}
        </p>
      </div>
    </div>
  )
};

const NewsPage = () => {
  const [hasLoadedAll, setHasLoadedAll] = useState(false);
  const { newsData, totalCount } = useAirtableData(hasLoadedAll ? 20 : 10);
  // console.log(newsData);

  const isShowLoadMore = useMemo(() => {
    return totalCount > newsData.length && !hasLoadedAll
  }, [hasLoadedAll, totalCount, newsData]);

  const handleLoadMore = () => {
    setHasLoadedAll(true);
  };

  return (
    <Layout>
      <Seo {...metaData} />
      <div className="container mx-auto">
        <div className="pt-24 pb-8 flex justify-center">
          <Heading variant="h1">
            QhAt News
          </Heading>
        </div>
        <div className="px-9 md:px-24">
          {newsData.map((data, idx) => <News key={data.id} isLast={idx === newsData.length -1} {...data} />)}
        </div>
        {isShowLoadMore && (
          <div className="pb-32 flex justify-center md:justify-end">
            <button 
              className="w-[180px] h-[50px] border border-[#CC9D02] rounded text-[#CC9D02] text-xl font-light text-center"
              onClick={handleLoadMore}
              >
              Load more
            </button>
          </div>
        )}
      </div>
    </Layout>
  )
};

export default NewsPage;